import Vue from 'vue'
import '@/utils/request';
import App from './App.vue'
import router from "./router";
import VueAMap from 'vue-amap';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "@/assets/css/common.scss"; // 导入全局reset样式

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: '3fa83a80e0d90222bc0813488da8e9d0', //上面步骤提到的key复制过来
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  v: '1.4.4'
});
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
