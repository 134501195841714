import Vue from "vue";
import VueRouter from "vue-router";
import HomePage from "@/pages/HomePage.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: HomePage,
  },
  {
    path: "/demo",
    name: "demo",
    component: () => import("../pages/demo.vue"),
  },
  {
    path: "/bottom",
    name: "bottom",
    component: () => import("../components/PageBottom.vue"),
  },
  {
    path: "/softproduct",
    name: "softproduct",
    component: () => import("../pages/software/SoftwareProduct.vue"),
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: () => import("../pages/software/aboutUs.vue"),
  },
  {
    path: "/scsl",
    name: "scsl",
    component: () => import("../pages/software/Scsl.vue"),
  },
  {
    path: "/ylyzl",
    name: "ylyzl",
    component: () => import("../pages/software/Yzl.vue"),
  },
  {
    path: "/xtqz",
    name: "xtqz",
    component: () => import("../pages/software/Xtqz.vue"),
  },
  {
    path: "/zcyzl",
    name: "zcyzl",
    component: () => import("../pages/software/Zcyzl.vue"),
  },
  {
    path: "/dzcjgd",
    name: "dzcjgd",
    component: () => import("../pages/software/Dzcjgd.vue"),
  },
  {
    path: "/hardware",
    name: "hardware",
    component: () => import("../pages/IntelligentHardware.vue"),
  },
  {
    path: "/latestDynamic",
    name: "latestDynamic",
    component: () => import("../pages/home/latestDynamic.vue"),
  },
  {
    path: "/dynamicInfo",
    name: "dynamicInfo",
    component: () => import("../pages/home/dynamicInfo.vue"),
  },
  {
    path: "/textInfo",
    name: "textInfo",
    component: () => import("../pages/home/textInfo.vue"),
  },
  {
    path: "/yzlPage",
    name: "yzlPage",
    component: () => import("../pages/software/yzlPage.vue"),
  },
  {
    path: "/hntPage",
    name: "hntPage",
    component: () => import("../pages/hntPage.vue"),
  },
  {
    path: "/toolPage",
    name: "toolPage",
    component: () => import("../pages/toolPage.vue"),
  },
  {
    path: "/helpPage",
    name: "helpPage",
    component: () => import("../pages/helpPage.vue"),
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.afterEach(() => {
  // 在路由跳转后滚动到页面顶部
  window.scrollTo(0, 0);
});
export default router;
