<template>
  <div v-scroll class="main_page" @scroll="handleScroll">
    <pagehead class="bgcolor" :isDefault="isDefault"></pagehead>
    <div class="company_poster">
      <div class="introduce">
        <div class="station_box" v-for="(item, index) in introduceList" :key="index">
          <div class="introduce_boxs">
            <div>
              <img :src="item.url" alt="" />
              <span>{{ item.title }}</span>
            </div>
            <p>{{ item.introduce }}</p>
          </div>
        </div>
      </div>
      <div class="companyInfo">
        <h1>豪米优量科技</h1>
        <h3>致力打造国内一流工程质量管理平台</h3>
        <!-- <h3>国内领先工程质量管理平台</h3>
        <h3>行业最佳电子档案智能平台</h3> -->
        <button class="ct" @click="$refs.requestPresentation.dialogVisible = true">申请演示</button>
      </div>
    </div>
    <div class="softwares">
      <div class="software_core">
        <div class="part1_bg">
          <img src="../assets/imgs/index/part1-bg.svg">
        </div>
        <div class="flex flex-wrap">
          <div class="part1_item0 relative" v-for="(item, index) in items0" :key="index">
            <img class="item0_pic w-full h-full" :src="item.picUrl" alt="" />
            <div class="item0_area absolute text-center" :class="'area_item' + index">
              <div class="area_title">{{ item.title }}</div>
              <div class="area_sketch">{{ item.sketch }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="four_status">
      <!-- <img src="../assets/imgs/index/part2-bg.svg"> -->
    </div>
    <div class="buy_streak cursor-pointer" @click="toBuy"></div>
    <div class="tutorials">
      <div class="demonstration_tutorials part4_core">
        <div class="tutorials_title part4_title text-center">演示教程</div>
        <div class="tutorials_video"></div>
      </div>
    </div>
    <div class="imagetext">
      <div class="image_text part4_core">
        <div class="dynamic_title part4_title text-center">深度了解</div>
        <div class="text_content">
          <div class="text_main flex flex-wrap">
            <div class="text_item w-full cursor-pointer flex items-center justify-between" v-for="(item, index) in textList" :key="index" @click="checkTxtOne(item)">
              <div class="text_title truncate">{{ item.title }}</div>
              <div class="text_time">{{ item.releaseTime.split(" ")[0] }}</div>
            </div>
          </div>
          <div class="text_footer" v-if="articleList.length > 0" @click="clickArrowChange()">
            <div class="text_btn text-center">展开<i class="el-icon-arrow-down" :class="{ 'el-icon-arrow-down-active': isShow }"></i></div>
          </div>
        </div>
      </div>
    </div>
    <div class="dynamic">
      <div class="latest_dynamic part4_core">
        <div class="dynamic_title part4_title text-center">新闻动态</div>
        <div class="dynamic_content flex flex-wrap">
          <div class="dynamic_item flex cursor-pointer" v-for="(item, index) in articleList" :key="index" @click="checkOne(item)">
            <div class="dynamic_left">
              <div class="left_p1">{{ item.month }}.{{ item.day }}</div>
              <div class="left_p2">{{ item.year }}</div>
            </div>
            <div class="dynamic_right">{{ item.title }}</div>
          </div>
        </div>
        <div class="dynamic_footer text-center" v-if="articleList.length > 0">
          <!-- <div class="dynamic_btn text-center m-auto cursor-pointer">查看更多</div> -->
          <el-button class="dynamic_btn" size="medium" @click="checkMore">查看更多</el-button>
        </div>
      </div>
    </div>
    <div class="cooperative_enterprise">
      <div class="center">
        <h3>与先进企业一起进步</h3>
        <div class="companys">
          <div class="company_box" v-for="(item, index) in companys1" :key="index">
            <img :src="item.companyLogo" alt="" />
            <h4>{{ item.companyName }}</h4>
          </div>
        </div>
        <div class="companys">
          <div class="company_box" v-for="(item, index) in companys2" :key="index">
            <img :src="item.companyLogo" alt="" />
            <h4>{{ item.companyName }}</h4>
          </div>
        </div>
      </div>
    </div>
    <request-presentation ref="requestPresentation" />
    <pagebottom></pagebottom>
  </div>
</template>

<script>
import requestPresentation from "@/components/requestPresentation.vue";
import pagebottom from "@/components/PageBottom.vue";
import pagehead from "@/components/PageTop.vue";
export default {
  components: {
    requestPresentation,
    pagebottom,
    pagehead,
  },
  data() {
    return {
      titleList: ["首页", "软件产品", "智能硬件", "帮助中心", "关于我们"],
      introduceList: [
        {
          url: require("../assets/imgs/index/classic.png"),
          title: "经典",
          introduce: "严谨而流畅、全面而简洁、强大而亲和、前卫有气质。",
        },
        {
          url: require("../assets/imgs/index/nature.png"),
          title: "生态",
          introduce: "同步最新规范、接口完整、各类资料均可导入导出线上签名盖章。",
        },
        {
          url: require("../assets/imgs/index/zn.png"),
          title: "智能",
          introduce: "大量的智能技术应用、快捷选取与编辑表、自动整理资料并归档。",
        },
        {
          url: require("../assets/imgs/index/yy.png"),
          title: "易用",
          introduce: "简洁流畅的窗口、熟悉的word和excel界面、任意调适的页面。",
        },
      ],
      items0: [
        {
          title: "云端畅享  随心所欲",
          sketch: "创新SAAS模式，扔掉加密狗，拥抱互联网，随时随地上网操作，总包分包不限人数，同平台做资料存资料，云端资料随时下载，做资料从未如此优雅。",
          picUrl: require("../assets/imgs/index/part1-item1-bg.svg"),
        },
        {
          title: "智能内核 效率飚升",
          sketch: "强大智能运算能力，自动计算分析，自动统计汇总，重复工序，十几甚至几十份表格同步编辑，一气呵成，更有自动组卷归档功能，告别繁琐与低效，工作效率上高速。",
          picUrl: require("../assets/imgs/index/part1-item2-bg.svg"),
        },
        {
          title: "电子签章 告别纸质",
          sketch: "与“协同电子签章”完美配套，技术资料瞬间生成原始电子档案，无需再线下跑腿签字盖章，电子档案可直接提交城建档案馆，无需纸质资料和扫描文档，降低成本棒棒的。",
          picUrl: require("../assets/imgs/index/part1-item3-bg.svg"),
        },
        {
          title: "生态融合  智能互联",
          sketch: "构件全面生态系统，提供完整软硬件接口，连接检测工具，实测实量数据自动导入，自动生成检验批，对接检测中心，检测报告在线自动接收，质监站档案馆线上核查资料，实现远程验收，线上协同新模式。",
          picUrl: require("../assets/imgs/index/part1-item4-bg.svg"),
        }
      ],
      isShow: false,
      pageF: {
        pageNumber: 1,
        pageSize: 100,
        articleType: 1
      },
      textList: [],
      pageD: {
        pageNumber: 1,
        pageSize: 6,
        articleType: 2
      },
      articleList: [],
      companys: [
        {
          companyName: "中铁十四局集团有限公司",
          companyLogo: require("../assets/imgs/index/zt14.png"),
        },
        {
          companyName: "宝业集团股份有限公司",
          companyLogo: require("../assets/imgs/index/baoye.png"),
        },
        {
          companyName: "长业建设集团有限公司",
          companyLogo: require("../assets/imgs/index/changye.png"),
        },
        {
          companyName: "中国建筑第八工程局",
          companyLogo: require("../assets/imgs/index/zjbj.png"),
        },
        {
          companyName: "绍兴临空管委会",
          companyLogo: require("../assets/imgs/index/sxlk.png"),
        },
        {
          companyName: "浙江环宇建设集团有限公司",
          companyLogo: require("../assets/imgs/index/zjhy.png"),
        },
        {
          companyName: "中铁十一局集团有限公司",
          companyLogo: require("../assets/imgs/index/zt14.png"),
        },
        {
          companyName: "浙江工程管理公司",
          companyLogo: require("../assets/imgs/index/gcgl.png"),
        },
        {
          companyName: "浙江建元工程管理有限公司",
          companyLogo: require("../assets/imgs/index/zjjy.png"),
        },
        {
          companyName: "绍兴市城市建设投资集团",
          companyLogo: require("../assets/imgs/index/sxct.png"),
        },
      ],
      companys1: [
        {
          companyName: "中铁十四局集团有限公司",
          companyLogo: require("../assets/imgs/index/zt14.png"),
        },
        {
          companyName: "宝业集团股份有限公司",
          companyLogo: require("../assets/imgs/index/baoye.png"),
        },
        {
          companyName: "长业建设集团有限公司",
          companyLogo: require("../assets/imgs/index/changye.png"),
        },
        {
          companyName: "中国建筑第八工程局",
          companyLogo: require("../assets/imgs/index/zjbj.png"),
        },
        {
          companyName: "绍兴临空管委会",
          companyLogo: require("../assets/imgs/index/sxlk.png"),
        }
      ],
      companys2: [
        {
          companyName: "浙江环宇建设集团有限公司",
          companyLogo: require("../assets/imgs/index/zjhy.png"),
        },
        {
          companyName: "中铁十一局集团有限公司",
          companyLogo: require("../assets/imgs/index/zt14.png"),
        },
        {
          companyName: "浙江工程管理公司",
          companyLogo: require("../assets/imgs/index/gcgl.png"),
        },
        {
          companyName: "浙江建元工程管理有限公司",
          companyLogo: require("../assets/imgs/index/zjjy.png"),
        },
        {
          companyName: "绍兴市城市建设投资集团",
          companyLogo: require("../assets/imgs/index/sxct.png"),
        },
      ],
      status: false,
      scrollTop: 0,
      isDefault: false, //默认没滑动
    };
  },
  directives: {
    scroll: {
      inserted: function (el, binding) {
        el.addEventListener("scroll", binding.value);
      },
      unbind: function (el, binding) {
        el.removeEventListener("scroll", binding.value);
      },
    },
  },
  created() {
    console.log("this.$router-homepage", this.$router.currentRoute);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.getFileData()
    this.getDymcData()
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll: function (event) {
      this.scrollTop = event.target.scrollTop;
      this.scrollTop?(this.isDefault = true):(this.isDefault = false);
       
    },
    getFileData () {
      this.$http.get('/officialArticle/articlePage', this.pageF).then(res => {
        console.log(res)
        if (res.code === 200) {
          this.textList = res.data.records.length > 0 ? res.data.records : []
        } else {
          this.$notification.error({
            message: '数据获取失败',
            description: res.msg,
            duration: 2.5
          })
        }
      })
    },
    getDymcData () {
      this.$http.get('/officialArticle/articlePage', this.pageD).then(res => {
        console.log(res)
        if (res.code === 200) {
          this.articleList = res.data.records.length > 0 ? res.data.records : []
          if (res.data.records.length > 0) {
            const articleList = res.data.records
            articleList.forEach((v, k) => {
              let arr = []
              arr = v.releaseTime.split(' ')[0].split('-')
              articleList[k].year = arr[0]
              articleList[k].month = arr[1]
              articleList[k].day = arr[2]
            })
            console.log(articleList)
            this.articleList = articleList
          }
        } else {
          this.$notification.error({
            message: '数据获取失败',
            description: res.msg,
            duration: 2.5
          })
        }
      })
    },
    goSort() {
      this.$router.push({
        path: "/softproduct",
      });
    },
    goDetail(index) {
      console.log(index);
      if (index == 0) {
        this.$router.push({
          path: "/scsl",
        });
      } else if (index == 1) {
        this.$router.push({
          path: "/ylyzl",
        });
      } else if (index == 2) {
        this.$router.push({
          path: "/xtqz",
        });
      } else if (index == 3) {
        this.$router.push({
          path: "/zcyzl",
        });
      } else if (index == 4) {
        this.$router.push({
          path: "/dzcjgd",
        });
      }
    },
    checkOne(item) {
      sessionStorage.setItem('dymcId', item.id)
      this.$router.push({
        path: "/dynamicInfo",
      })
    },
    checkMore() {
      this.$router.push({
        path: "/latestDynamic",
      })
    },
    checkTxtOne(item) {
      sessionStorage.setItem('textId', item.id)
      this.$router.push({
        path: "/textInfo",
      })
    },
    clickArrowChange() {
      this.isShow = !this.isShow
    },
    toBuy() {
      window.open('https://multiport.abuildingcloud.com/')
    }
  },
};
</script>
<style lang="scss" scoped>
body {
  // 视口宽度小于1920px
  @media only screen and (max-width: 1920px) {
    .company_poster {
      background-position: center !important;
    }
    .cooperative_enterprise {
      background-position: center !important;
    }
  }
  @media only screen and (max-width: 1200px) {
  }
  // @media only screen and(min-height:0px) and (max-height: 1200px) {
  //   .slicing {
  //     margin-bottom: 20px;
  //   }
  //   .pages {
  //     margin-bottom: 20px;
  //   }
  // }
}
// body {
//   height: 100vh; /* 100%视口高度 */
//   margin: 0;
//   padding: 0;
//   overflow: hidden; /* 隐藏默认滚动条 */
// }
// /*设置滚动条的宽度和颜色*/
// ::-webkit-scrollbar {
//   width: 16px;
//   height: 10px;
//   background-color: #f1f1f1;
//   position: fixed;
//   z-index: 9999999;
//   overflow-y: auto;
// }
// // /*定义滚动条的滑块背景颜色*/
// ::-webkit-scrollbar-thumb {
//   background-color: #c1c1c1;
// }
// // /*设置滚动条的圆角*/
// ::-webkit-scrollbar-thumb {
//   border-radius: 10px;
// }
// // /*设置滚动条的轨道的背景色*/
// ::-webkit-scrollbar-track {
//   background-color: #f5f5f5;
// }
// // /*定义滚动条轨道的阴影效果*/
// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
// }
// // /*定义滚动条滑块如何响应鼠标事件*/
// ::-webkit-scrollbar-thumb:hover {
//   background-color: #b1b1b1;
// }
/* 设置向上滚动按钮样式 */
// ::-webkit-scrollbar-button:start {
//   background-color: #ccc; /* 按钮背景颜色 */
//   background-image: url('up-arrow.png'); /* 向上箭头图标 */
//   background-size: cover; /* 图标填充整个按钮 */
// }

// /* 设置向下滚动按钮样式 */
// ::-webkit-scrollbar-button:end {
//   background-color: #ccc; /* 按钮背景颜色 */
//   background-image: url('down-arrow.png'); /* 向下箭头图标 */
//   background-size: cover; /* 图标填充整个按钮 */
// }

.main_page {
  height: 100%;
  font-family: PingFang SC, PingFang SC;
  overflow: scroll;
  .head_bar {
    height: 80px;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 80px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9999;
    // background-color: rgba($color: #B6CCE3, $alpha: 0.5);

    .left {
      margin-right: 80px;
      cursor: pointer;
    }
    .center {
      .title_boxs {
        display: flex;
        font-size: 18px;
        font-weight: 600;
        // color: #0f73ff;
        line-height: 21px;
        .title_box {
          width: 120px !important;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-content: center;
          padding-top: 28px;
          cursor: pointer;
          // span {
          //   border-bottom: 4px solid transparent;
          //   font-size: 16px;
          //   font-weight: 400;
          //   color: #3d424d;
          // }
        }
        .title_box:nth-child(1) {
          color: #0f73ff;
          span {
            border-bottom: 4px solid #0f73ff !important;
            font-size: 18px !important;
            font-weight: 600 !important;
            color: #0f73ff !important;
          }
        }
        // .title_box:hover {
        //   color: #0f73ff;
        // }
        // .title_box:hover span {
        //   border-bottom: 4px solid #0f73ff;
        //   font-size: 18px;
        //   font-weight: 600;
        //   color: #0f73ff;
        // }
        ul {
          display: flex;
        }
        li {
          height: 80px !important;
          text-align: center !important;
        }
      }
    }
    .right {
      position: absolute;
      top: 23px;
      right: 80px;
      button {
        width: 88px;
        height: 36px;
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        border: 1px solid #0f73ff;
        background-color: transparent;
        font-size: 14px;
        font-weight: 400;
        color: #0f73ff;
        // cursor: pointer;
      }
    }
    .right:hover button {
      background-color: rgba($color: #0f73ff, $alpha: 0.8);
      color: white;
    }
  }
  .company_poster {
    background: url("../assets/imgs/index/index_bg.png") no-repeat;
    height: 640px;
    background-size: cover;
    background-position: 60% 12%;
    position: relative;
    .introduce {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      position: absolute;
      margin: auto;
      left: 0px;
      right: 0px;
      bottom: -80px;
      .station_box:hover .introduce_boxs {
        // position: relative;
        // top: -13px;
        margin-top: -15px;
        box-shadow: 0 2px 5px 2px rgba(#0084ff, 0.1);
      }
      .introduce_boxs {
        width: 274px;
        height: 118px;
        transition: top 2s ease;
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 2px;
        opacity: 1;
        padding: 18px 12px 18px 18px;
        transition: all 0.2s;
        // border: 10px solid red;

        cursor: pointer;
        div {
          display: flex;
          align-items: center;
          margin-bottom: 11px;
          span {
            font-size: 20px;
            font-weight: 600;
            color: #3d424d;
          }
        }
        img {
          margin-right: 10px;
        }
        p {
          font-size: 14px;
          font-weight: 400;
          color: #718091;
          line-height: 22px;
        }
      }
    }
    .companyInfo {
      position: absolute;
      top: 200px;
      left: 455px;
      h1 {
        font-size: 40px;
        font-family: PingFang HK, PingFang HK;
        font-weight: 600;
        color: #3d424d;
        margin-bottom: 24px;
      }
      h3 {
        font-size: 24px;
        font-weight: 400;
        color: #3d424d;
        line-height: 28px;
        margin-bottom: 16px;
      }
      button {
        width: 88px;
        height: 36px;
        margin-top: 32px;
        background: #0f73ff;
        border-radius: 2px;
        opacity: 1;
        text-align: center;
        box-sizing: border-box;
        border: none;
        color: white;
      }
      button:hover {
        background-color: rgba($color: #0f73ff, $alpha: 0.8);
      }
    }
  }
  .softwares {
    width: 100%;
    background-color: #ffffff;
    padding-top: 178px;
    .post_box {
      display: flex;
      align-items: center;
    }
    .btn_change {
      border-radius: 4px;
      border: 1px solid #0e71ff;
      background-color: #fff;
      color: #0e71ff;
      font-size: 14px;
      font-weight: 400;
      padding: 8px;
      display: flex;
      align-items: center;
      align-content: center;
    }
    .img_set {
      color: #0e71ff;
      // background-color: #0e71ff;
      margin-left: 8px;
    }
    .software_core {
      margin: 0 auto;
      width: 1200px;
      height: 100%;
      .part1_bg {
        height: 400px;
        margin-bottom: 20px;
      }
      .part1_item0 {
        width: calc((100% - 20px) / 2);
        height: 400px;
        margin-right: 20px;
        margin-bottom: 20px;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
      .item0_area {
        width: 430px;
        top: 60px;
        left: 50%;
        transform: translateX(-50%);
        .area_title {
          font-size: 24px;
          font-weight: 400;
          margin-bottom: 30px;
        }
        .area_sketch {
          font-size: 16px;
          font-weight: 400;
        }
      }
      .area_item0 {
        color: #3D424D;
      }
      .area_item1 {
        color: #FFFFFF;
      }
      .area_item2 {
        color: #FFFFFF;
      }
      .area_item3 {
        color: #3D424D;
      }
    }
  }
  .four_status {
    width: 100%;
    height: 560px;
    background: url("../assets/imgs/index/part2-bg.svg") no-repeat;
    background-size: cover;
    background-position: center;
  }
  .buy_streak {
    width: 100%;
    height: 139px;
    background: url("../assets/imgs/index/part3-bg.svg") no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 100px;
  }
  .part4_core {
    width: 1200px;
    margin: auto;
    .part4_title {
      font-weight: 600;
      font-size: 40px;
      color: #3D424D;
      margin-bottom: 60px;
    }
  }
  .tutorials {
    margin-bottom: 100px;
    .demonstration_tutorials {
      .tutorials_video {
        width: 100%;
        height: 575px;
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
  .dynamic {
    margin-bottom: 77px;
    .latest_dynamic {
      .dynamic_title {
        margin-bottom: 77px;
      }
      .dynamic_content {
        width: 100%;
        margin-bottom: 10px;
        .dynamic_item {
          width: calc((100% - 190px) / 2);
          height: 89px;
          background-color: #F9F9F9;
          border-radius: 8px;
          margin-right: 30px;
          margin-bottom: 30px;
          padding: 30px 40px;
          &:nth-child(2n) {
            margin-right: 0;
          }
          .dynamic_left {
            border-bottom: 1px solid #D9D9D9;
            .left_p1 {
              font-weight: 600;
              font-size: 32px;
              color: #333333;
              padding-bottom: 4px;
            }
            .left_p2 {
              font-weight: 400;
              font-size: 16px;
              color: #999999;
            }
          }
          .dynamic_right {
            font-weight: 600;
            font-size: 18px;
            color: #000000;
            padding-top: 4px;
            padding-left: 20px;
          }
        }
      }
      .dynamic_footer {
        .dynamic_btn {
          width: 86px;
          height: 36px;
          padding: 0;
          border-radius: 2px;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
        }
      }
    }
  }

  .text_content {
    width: calc(100% - 300px);
    background: #F9F9F9;
    border-radius: 10px;
    padding: 40px 150px;
    margin-bottom: 100px;
    .text_main {
      padding-bottom: 40px;
    }
    .text_item {
      padding: 17px 0;
      border-bottom: 1px solid #E9E9E9;
      .text_title {
        width: 750px;
        font-weight: 600;
        font-size: 16px;
        color: #000000;
      }
      .text_time {
        font-weight: 400;
        font-size: 16px;
        color: #999999;
      }
    }
    .text_btn {
      width: 86px;
      height: 34px;
      line-height: 36px;
      border-radius: 2px;
      border: 1px solid #D9D9D9;
      cursor: pointer;
      color: #666666;
      font-size: 14px;
      margin: auto;
      &:hover {
        border-color: #c6e2ff;
        background-color: #ecf5ff;
      }
    }
    .el-icon-arrow-down {
      transition: all 0.3s;
    }
    .el-icon-arrow-down-active {
      transform: rotate(180deg);
      transform-origin: center center;
    }
  }
  
  .cooperative_enterprise {
    color: white;
    width: 100%;
    height: 640px;
    background: url("../assets/imgs/index/xj_companys.png") no-repeat;
    background-size: cover;
    position: relative;
    .center {
      width: 1200px;
      position: absolute;
      margin: auto;
      left: 0px;
      right: 0px;
      top: 114px;
      h3 {
        text-align: center;
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 60px;
      }
      .companys {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .company_box {
          width: 270px;
          // height: 130px;
          padding-top: 22px;
          padding-bottom: 24px;
          // box-sizing: border-box;
          text-align: center;
          margin: 0 21px 32px 0;
          background: rgba(255, 255, 255, 0.1);
          img {
            margin-bottom: 7px;
            cursor: default;
          }
        }
      }
    }
  }
  .common_width {
    width: 1200px !important;
  }
  .anotherItem .shadow_top {
    height: 132px !important;
  }
  .ct {
    font-size: 14px;
  }
  .first_in:nth-child(2) {
    margin-left: 16px;
    margin-right: 16px;
  }
  .first_in:nth-child(4) {
    margin-right: 16px;
  }
  .second_in {
    margin-top: 15px;
  }
  .third_in {
    margin-top: 15px;
  }
  .second_in:nth-child(2) {
    margin-left: 16px;
    margin-right: 16px;
  }
  .third_in:nth-child(1) {
    margin-right: 16px;
  }
  .third_in:nth-child(1) span {
    width: 100px !important;
  }
  .third_in:nth-child(2) span {
    width: 110px !important;
  }
  .btn_change:hover {
    cursor: pointer;
    background: #0f73ff !important;
    color: white !important;
    /* content: url("../assets/imgs/index/rightIcon.png"); */
  }
  .btn_change:hover .img_set {
    content: url("../assets/imgs/index/rightIcon.png");
  }
}
</style>
