<template>
  <div class="main-top">
    <div :class="isDefault ? 'changeBg' : ''">
      <header class="head_bar" :class="
          defaultIndex == 0
            ? 'index0'
            : defaultIndex == 1
            ? 'index1'
            : defaultIndex == 2
            ? 'index2'
            : defaultIndex == 3
            ? 'index3'
            : defaultIndex == 4
            ? 'index4'
            : 'index5'
        ">
        <div class="left">
          <img :src="imgUrl" alt="" />
        </div>
        <div class="center" @mouseleave="hoverOut()">
          <ul class="title_boxs">
            <li :class="defaultIndex == index || (item == '质量数智平台' && currentPath == '/yzlPage') ? 'title_boxIn' : ''" class="title_box" v-for="(item, index) in titleList"
              :key="index" @click="routerJump(index)" @mouseenter="hoverIn(index)">
              <span>{{ item }}</span>
            </li>
          </ul>
          <transition name="el-fade-in-linear">
            <div v-show="showStatus" @mouseenter="hoverIn(1)" @mouseleave="hoverOut()" class="second_bar">
              <div style="
                  width: 100%;
                  height: 4px;
                  background-color: transparent;
                  margin-bottom: 4px;
                "></div>
              <div class="second_bar_box">
                <div v-for="(item, index) in secondList" :key="index">
                  <div v-if="index == 0" class="title_introduce">
                    <div class="line"></div>
                    <h3 class="h3_first">组合产品</h3>
                  </div>
                  <div v-if="index == 3" class="title_introduce">
                    <div class="line"></div>
                    <h3 class="h3_second">单个产品</h3>
                  </div>
                  <div class="select_part">
                    <p :class="item.path == currentPath ? 'selected' : ''" @click="gotoPath(item.path)">
                      {{ item.title }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div class="right">
          <button @click="$refs.requestPresentation.dialogVisible = true">申请演示</button>
        </div>
      </header>
    </div>
    <request-presentation ref="requestPresentation" />
  </div>
</template>

<script>
import requestPresentation from "@/components/requestPresentation.vue";
export default {
  components: {
    requestPresentation,
  },
  props: ["isDefault"],
  data() {
    return {
      // titleList: ["首页", "质量数智平台", "智能实测实量", "混凝土数智平台", "数智工具", "帮助中心", "关于我们"],
      titleList: ["首页", "质量数智平台", "智能实测实量", "混凝土数智平台", "帮助中心", "关于我们"],
      defaultIndex: 0,
      imgUrl: require("../assets/imgs/index/head_title.png"),
      showStatus: false,
      currentPath: "",
      secondList: [
        {
          title: "工程质量管理数智平台",
          path: "/softproduct",
        },
        {
          title: "智测云资料",
          path: "/zcyzl",
        },
        {
          title: "电子城建归档",
          path: "/dzcjgd",
        },
        {
          title: "智能实测实量",
          path: "/scsl",
        },
        {
          title: "优量云资料",
          path: "/ylyzl",
        },
        {
          title: "协同电子签章",
          path: "/xtqz",
        },
      ],
    };
  },
  watch: {},
  created() {
    console.log("this.isDefault", this.isDefault);
    // setInterval(()=>{
    //   console.log('setTimeout====>1s执行了',this.isDefault,this.zzStatus);
    // },1000)
    // console.log("通用header进来了");
    // 通过导航栏跳转路由
    // if (sessionStorage.getItem("routerIndex")) {
    //   this.defaultIndex = sessionStorage.getItem("routerIndex");
    //   if (this.defaultIndex == 0) {
    //     this.imgUrl = require("../assets/imgs/index/head_title.png");
    //   } else if (this.defaultIndex == 1) {
    //     this.imgUrl = require("../assets/imgs/softproduct/logo.png");
    //   }
    // }
    // 直接通过当前路由判断导航栏样式
    console.log(this.$router.currentRoute.path);
    let path = this.$router.currentRoute.path;
    this.currentPath = path;
    if (path == "/") {
      this.defaultIndex = 0;
      this.imgUrl = require("../assets/imgs/index/head_title.png");
    } else if (path == "/yzlPage") {
      this.defaultIndex = 11;
      this.imgUrl = require("../assets/imgs/index/head_title.png");
    }
    else if (
      path == "/softproduct" ||
      path == "/scsl" ||
      path == "/ylyzl" ||
      path == "/xtqz" ||
      path == "/zcyzl" ||
      path == "/dzcjgd"
    ) {
      this.defaultIndex = 1;
      this.imgUrl = require("../assets/imgs/softproduct/logo.png");
    } else if (path == "/hardware") {
      this.defaultIndex = 2;
      this.imgUrl = require("../assets/imgs/index/head_title.png");
    } else if (path == "/hntPage") {
      this.defaultIndex = 3;
      this.imgUrl = require("../assets/imgs/index/head_title.png");
    } 
    // else if (path == "/toolPage") {
    //   this.defaultIndex = 4;
    //   this.imgUrl = require("../assets/imgs/index/head_title.png");
    // } 
    else if (path == "/helpPage") {
      this.defaultIndex = 4;
      this.imgUrl = require("../assets/imgs/index/head_title.png");
    } 
    else if (path == "/aboutUs" || path == "/latestDynamic" || path == "/dynamicInfo" || path == "/textInfo") {
      this.defaultIndex = 5;
      this.imgUrl = require("../assets/imgs/index/head_title.png");
    }
  },
  methods: {
    routerJump(index) {
      if (sessionStorage.getItem('textId')) sessionStorage.removeItem('textId')
      if (sessionStorage.getItem('dymcId')) sessionStorage.removeItem('dymcId')
      if (index == 0 && this.$router.currentRoute.path != "/") {
        sessionStorage.setItem("routerIndex", index);
        this.$router.push({
          path: "/",
        });
      } else if (
        index == 1 &&
        this.$router.currentRoute.path != "/yzlPage"
      ) {
        sessionStorage.setItem("routerIndex", index);
        this.$router.push({
          path: "/yzlPage",
        });
      } else if (index == 2 && this.$router.currentRoute.path != "/hardware") {
        sessionStorage.setItem("routerIndex", index);
        this.$router.push({
          path: "/hardware",
        });
      } else if (index == 3 && this.$router.currentRoute.path != "/hntPage") {
        sessionStorage.setItem("routerIndex", index);
        this.$router.push({
          path: "/hntPage",
        })
      }
      // else if (index == 4 && this.$router.currentRoute.path != "/toolPage") {
      //   sessionStorage.setItem("routerIndex", index);
      //   this.$router.push({
      //     path: "/toolPage",
      //   });
      // }
      else if (index == 4 && this.$router.currentRoute.path != "/helpPage") {
        sessionStorage.setItem("routerIndex", index);
        this.$router.push({
          path: "/helpPage",
        });
      }
      else if (index == 5 && this.$router.currentRoute.path != "/aboutUs") {
        sessionStorage.setItem("routerIndex", index);
        this.$router.push({
          path: "/aboutUs",
        });
      }
      let temp = JSON.parse(JSON.stringify(this.titleList));
      this.titleList = temp;
    },
    gotoPath(router) {
      console.log("router", router);
      // 二级路由跳转判断
      if (this.currentPath != router) {
        this.$router.push({
          path: router,
        });
      }
    },
    hoverIn(index) {
      // console.log("hoverIn", index);
      index == 1 ? (this.showStatus = true) : (this.showStatus = false);
    },
    hoverOut() {
      // console.log('hoverOut进来了');
      this.showStatus = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-top {
  .head_bar {
    height: 80px;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 80px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9999;
    // background-color: rgba($color: #B6CCE3, $alpha: 0.5);

    .left {
      margin-right: 80px;
      cursor: pointer;
    }
    .center {
      .title_boxs {
        display: flex;
        font-size: 18px;
        font-weight: 600;
        // color: #0f73ff;
        line-height: 21px;
        .title_box {
          // width: 120px !important;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-content: center;
          padding-top: 28px;
          cursor: pointer;
          margin-right: 56px;
          span {
            border-bottom: 4px solid transparent;
            font-size: 16px;
            font-weight: 400;
            color: #3d424d;
          }
        }
        .title_box:hover {
          color: #0f73ff;
        }
        .title_box:hover span {
          // border-bottom: 4px solid #0f73ff;
          font-size: 18px;
          font-weight: 600;
          color: #0f73ff;
        }
        ul {
          display: flex;
        }
        li {
          height: 80px !important;
          text-align: center !important;
        }
      }
    }
    .right {
      position: absolute;
      top: 23px;
      right: 80px;
      button {
        width: 88px;
        height: 36px;
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        border: 1px solid #0f73ff;
        background-color: transparent;
        font-size: 14px;
        font-weight: 400;
        color: #0f73ff;
        cursor: pointer;
      }
    }
    .right:hover button {
      background-color: rgba($color: #0f73ff, $alpha: 0.8);
      color: white;
    }
  }
  .second_bar {
    width: 200px !important;
    box-sizing: border-box;
    // padding-top: 4px;
    color: black;
    position: fixed;
    z-index: 999999;
    left: 444px;
    top: 80px;
    text-align: center;

    .second_bar_box {
      background-color: #fff;
      padding-top: 4px;
      height: 293px !important;
    }
    .title_introduce {
      display: flex;
      align-items: center;
      padding-left: 16px;
      margin-bottom: 4px;
      // padding: 6px 0;
      padding-top: 6px;
      padding-bottom: 6px;
      .line {
        width: 4px;
        height: 16px;
        background: #0f73ff;
        margin-right: 6px;
      }
      h3 {
        font-weight: 600;
        font-size: 14px;
        color: #3d424d;
        padding: 6px 0px;
      }
      .h3_first {
        font-weight: 600;
      }
      .h3_second {
        font-weight: 600;
      }
    }
    // display: flex;
    // justify-content: center;
    // padding-left: 360px;
    // align-items: center;
    .select_part {
      display: flex;
      justify-content: flex-start;
      padding-left: 26px;
      margin-bottom: 4px;
      p {
        font-weight: 400;
        font-size: 14px;
        color: #718091;
        line-height: 16px;
        line-height: 16px;
        text-align: left;
        display: inline-block;
        cursor: pointer;
        padding: 6px 0;
      }
      p:hover {
        color: #0f73ff;
      }
    }

    // display: none;
  }
  // 设置选中栏的样式
  .title_boxIn {
    color: #0f73ff !important;
    span {
      border-bottom: 4px solid #0f73ff !important;
      font-size: 18px !important;
      font-weight: 600 !important;
      color: #0f73ff !important;
    }
  }
  // .index0 {
  //   background-image: linear-gradient(
  //     to right,
  //     #d8e6f7,
  //     #cbdbef,
  //     #b4cbe6,
  //     #b6cce3
  //   ) !important;
  // }
  .index1 {
    // background-image: linear-gradient(
    //   to right,
    //   #3b78f1,
    //   #4186f3,
    //   #4cabff,
    //   #59caff
    // ) !important;
    span {
      color: #fff !important;
    }
    .right {
      button {
        background-color: transparent;
        color: #fff !important;
        border: 1px solid #fff;
      }
    }
    .title_boxIn {
      color: #fff !important;
      span {
        border-bottom: 4px solid #fff !important;
        font-size: 18px !important;
        font-weight: 600 !important;
        color: #fff !important;
      }
    }
  }

  // .index2 {
  //   background: #fff !important;
  // }
  .selected {
    color: #0f73ff !important;
  }
  .changeBg {
    .index0 {
      background-color: #fff !important;
    }
    .index1 {
      background-image: linear-gradient(
        to right,
        #3b78f1,
        #4186f3,
        #4cabff,
        #59caff
      ) !important;
    }
    .index2 {
      background-color: #fff !important;
    }
    .index3 {
      background-color: #fff !important;
    }
    .index4 {
      background-color: #fff !important;
    }
    .index5 {
      background-color: #fff !important;
    }
  }
}
</style>
